interface choiseInit {
    choice_question_answer: choise_answe<any>
    code: string
    createAt: string
    desc: string
    difficulty: number
    falg: number
    select_answer: string
    tagsId: number
    tid: number
    title: string
    topic_answer_Tags: tagsInit
    type: number
    updateAt: string
}
interface choise_answe<T> {
    aid: number
    answer: string
    answer_title: object | T
    titleId: number
}
interface tagsInit {
    id: number
    img: string
    tag: string
}
export class ChoiceAnswerList {
    list: choiseInit[] = []
}

// 成绩单
interface reportInit {
    choice_question_titlt: choiseInit
    is_right: true
    report_id: number
    rid: number
    title_id: number
    user_answer: string
}
interface reportdata {
    cid: number | null
    createAt: string | null
    grade: string | null
    mode: string | null
    repport: number | any
    total: number | null
    user_id: number | null
}

export class ReportAnswerList {
    list: reportInit[] = []
    data: reportdata = {
        cid: null,
        createAt: null,
        grade: null,
        mode: null,
        repport: 0,
        total: null,
        user_id: null
    }
}

export interface InitReportData<T> {
    cid: number | T
    createAt: string | T
    grade: string | T
    mode: string | T
    repport: number | T
    total: number | T
}
export class ReportList {
    data: InitReportData<any>[] = []
}

interface detailInit {
    choice_question_answer: answerInit
    code: string
    createAt: string
    desc: string
    difficulty: number
    falg: number
    select_answer: string
    tagsId: number
    tid: number
    title: string
    topic_answer_Tags: { id: number; tag: string; img: string }
    type: number
    updateAt: string
}
interface answerInit {
    aid: number
    answer: string
    answer_title: string
    titleId: number
}
export class ChoiceDetail {
    Tags: string = ''
    desc: string = ''
    code: string = ''
    difficulty: number = 1
    select_answer: string = ''
    tid: number | any = null
    title: string = ''
    type: number = 0
    list: answerInit[] = []
}
